exports.components = {
  "component---themes-brochureware-src-pages-404-tsx": () => import("./../../../../../themes/brochureware/src/pages/404.tsx" /* webpackChunkName: "component---themes-brochureware-src-pages-404-tsx" */),
  "component---themes-brochureware-src-templates-article-listing-tsx": () => import("./../../../../../themes/brochureware/src/templates/ArticleListing.tsx" /* webpackChunkName: "component---themes-brochureware-src-templates-article-listing-tsx" */),
  "component---themes-brochureware-src-templates-article-tsx": () => import("./../../../../../themes/brochureware/src/templates/Article.tsx" /* webpackChunkName: "component---themes-brochureware-src-templates-article-tsx" */),
  "component---themes-brochureware-src-templates-help-centre-article-tsx": () => import("./../../../../../themes/brochureware/src/templates/HelpCentreArticle.tsx" /* webpackChunkName: "component---themes-brochureware-src-templates-help-centre-article-tsx" */),
  "component---themes-brochureware-src-templates-help-centre-topic-tsx": () => import("./../../../../../themes/brochureware/src/templates/HelpCentreTopic.tsx" /* webpackChunkName: "component---themes-brochureware-src-templates-help-centre-topic-tsx" */),
  "component---themes-brochureware-src-templates-help-centre-tsx": () => import("./../../../../../themes/brochureware/src/templates/HelpCentre.tsx" /* webpackChunkName: "component---themes-brochureware-src-templates-help-centre-tsx" */),
  "component---themes-brochureware-src-templates-page-tsx": () => import("./../../../../../themes/brochureware/src/templates/Page.tsx" /* webpackChunkName: "component---themes-brochureware-src-templates-page-tsx" */),
  "component---themes-brochureware-src-templates-service-status-tsx": () => import("./../../../../../themes/brochureware/src/templates/ServiceStatus.tsx" /* webpackChunkName: "component---themes-brochureware-src-templates-service-status-tsx" */)
}

