/**
 * Replaces the site URL in the given string with the main site URL for various CMS environments.
 *
 * @param {string} content - The string in which the URL needs to be replaced.
 * @returns {string} - The modified string with the URL replaced.
 */
export const replaceSiteUrl = (content: string) => {
  if (content && typeof content === 'string') {
    return content.replace(/(http|https):\/\/(localhost:\d+|gatsbyjs.io|cms-(dev|staging|test|live)\.zemplerbank\.com)/gi, (match, p1, p2) => {
      return match.includes('cms-') ? match.replace(/cms-(dev|staging|test|live)\./, '') : '';
    });
  }
  return '';
};
