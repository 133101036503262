import format from 'date-fns/format';

export const formatDate = (
  date: Date | string | undefined,
  shortFormat = false
) => {
  if (!date) {
    return '';
  }

  try {
    const inputDate = typeof date === 'string' ? new Date(date) : date;
    const formatString = shortFormat ? 'MMM yyyy' : 'd MMMM yyyy';
    return format(inputDate, formatString);
  } catch (error) {
    return '';
  }
};
