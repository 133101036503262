import React, { FC } from "react";
import { Icon, IconProps } from "../Icon";

export const Facebook: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M19.2 3.2H4.8a1.6 1.6 0 0 0-1.6 1.6v14.4a1.6 1.6 0 0 0 1.6 1.6h8v-7.2h-2.4v-2.4h2.4V9.911c0-2.44 1.189-3.511 3.217-3.511.971 0 1.485.072 1.728.105V8.8h-1.383c-.861 0-1.162.454-1.162 1.374V11.2h2.523l-.342 2.4H15.2v7.2h4a1.6 1.6 0 0 0 1.6-1.6V4.8a1.6 1.6 0 0 0-1.6-1.6Z"
    />
  </Icon>
);
