import { header } from "../../mockData/header";

const layers = {
  base: 0,
  videoOverlay: 1,
  processConnector: 5,
  articleCategory: 10,
  helpCenterOverlay: 15,
  helpCenterSearchInput: 25,
  helpSearchResults: 30,
  header: 35,
  overlay: 40,
  navigationDropdown: 45,
  blade: 50,
} as const;

export default layers;

export type ThemeLayers = typeof layers;
