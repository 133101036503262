import React, { FC, useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { useQueryReferrer } from '../../context/QueryReferrerContext';

interface LinkToProps {
  to: string;
  onClick?: any;
  className?: string;
  state?: any;
  id?: string;
  label?: string;
  title?: string;
  partiallyActive?: boolean;
  target?: string;
  children?: any;
}

export const LinkTo: FC<LinkToProps> = ({
  to,
  children,
  label,
  onClick,
  state,
  title,
  id,
  partiallyActive,
  target,
  ...rest
}) => {
  const [link, setLink] = useState<string>(to);
  const { queryString, referrer } = useQueryReferrer();
  const domain = process.env.GATSBY_FRONTEND_URL;

  useEffect(() => {
    if (to?.startsWith('mailto:')) {
      const obfuscatedEmail = to.replace('mailto:', '').split('').map(char => `&#${char.charCodeAt(0)};`).join('');
      setLink(`mailto:${obfuscatedEmail}`);
    }
  }, [to]);

  const shouldAppendQueryString = link?.includes('zemplerbank.com/forms/');

  const appendQueryString = (url: string) => {
    if (!queryString && !referrer) return url;

    const hasQueryString = url.includes('?');
    const separator = hasQueryString ? '&' : '?';
    const qs = queryString ? `${separator}${queryString}` : '';
    const ref = referrer ? `&ref=${encodeURIComponent(referrer)}` : '';
    return `${url}${qs}${ref}`;
  };

  let isExternal = link?.trim().startsWith('http') || link?.trim().startsWith('www') || link?.trim().startsWith('mailto:');
  const isAnchor = link?.trim().startsWith('#');
  const isTel = link?.trim().startsWith('tel');
  const isFile = /\.[0-9a-z]+$/i.test(link);

  if (link && isExternal && domain && link?.includes(domain)) {
    const [, extractedLink] = link.split(domain);
    setLink(extractedLink);
    isExternal = false;
  }

  const finalLink = shouldAppendQueryString ? appendQueryString(link) : link;

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (to?.startsWith('mailto:')) {
      event.preventDefault();
      window.location.href = to;
    }
    if (onClick) {
      onClick(event);
    }
  };

  return target === '_blank' || isExternal || isAnchor || isTel || isFile || !to ? (
    <a
      href={finalLink}
      target={target || (isExternal ? '_blank' : '')}
      rel={isExternal ? 'noopener noreferrer' : ''}
      onClick={handleClick}
      id={id}
      title={title}
      {...rest}
    >
      {label || children}
    </a>
  ) : (
    <Link
      to={finalLink}
      id={id}
      activeClassName="is-active"
      title={title}
      state={state}
      partiallyActive={partiallyActive}
      target={target}
      onClick={handleClick}
      {...rest}
    >
      {label || children}
    </Link>
  );
};

export default LinkTo;
