import React, { FC } from "react";
import { Icon, IconProps } from "../Icon";

export const Search: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M9 2C5.146 2 2 5.146 2 9s3.146 7 7 7a6.958 6.958 0 0 0 4.574-1.719l.426.426V16l5.586 5.586a1.415 1.415 0 0 0 2-2L16 14h-1.293l-.426-.426A6.958 6.958 0 0 0 16 9c0-3.854-3.146-7-7-7Zm0 2c2.773 0 5 2.227 5 5s-2.227 5-5 5-5-2.227-5-5 2.227-5 5-5Z"
    />
  </Icon>
);
