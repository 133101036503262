/* eslint-disable react/no-danger */
import { ThemeProvider } from "@emotion/react";
import React from "react";
import { GatsbySSR } from "gatsby";
import GlobalStyles from "./src/styles/GlobalStyles";
import theme from "./src/styles/tokens";
import { ScriptsBefore } from "./src/components/ScriptsBefore";
import { QueryReferrerProvider } from "./src/context/QueryReferrerContext";

export const wrapPageElement: GatsbySSR["wrapPageElement"] = ({
  element,
  props,
}) => (
  <>
    {/* Element */}
    {element}
  </>
);

export const wrapRootElement: GatsbySSR["wrapRootElement"] = ({ element }) => (
  <ThemeProvider theme={theme}>
    <QueryReferrerProvider>
      <ScriptsBefore />
      <GlobalStyles />
      {element}
    </QueryReferrerProvider>
  </ThemeProvider>
);
