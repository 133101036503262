import React, { FC } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { types } from 'util';

// Interface

export interface ContainerProps {
  children?: React.ReactNode;
  width?: 'narrow' | 'standard' | 'wide' | 'extraWide';
}

interface StyledContainerProps {
  width?: string;
}

// Styles

const StyledContainer = styled.div<StyledContainerProps>`
  ${({ theme: { space, media }, width }) => css`
    margin: 0 auto;
    padding: 0 ${space.small};
    max-width: ${width};

    @media (min-width: ${media.medium}) {
      padding: 0 ${space.large};
    }

    @media (min-width: ${media.xLarge}) {
      padding: ${width === 'none' ? `0 ${space.xLarge}` : `0`};
    }
  `};
`;

// JSX

export const Container: FC<React.PropsWithChildren<ContainerProps>> = ({
  children,
  width = 'standard',
  ...rest
}) => {
  let maxWidth = '1200px';

  switch (width) {
    case 'narrow':
      maxWidth = '808px';
      break;
    case 'standard':
      maxWidth = '1200px';
      break;
    case 'wide':
      maxWidth = '1296px';
      break;
    case 'extraWide':
      maxWidth = 'none';
      break;
  }

  return (
    <StyledContainer width={maxWidth} {...rest}>
      {children}
    </StyledContainer>
  );
};

export default Container;
