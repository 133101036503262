import { SitewideBannerProps } from '@zempler/theme/src/components/sitewideBanner/SitewideBanner';
import React, { createContext, useContext, ReactNode, useMemo } from 'react';

interface PageContextProps {
  pageID: string;
  pageUrl: string;
  sitewideBanner?: SitewideBannerProps;
  hideNavigation?: boolean;
}

export const PageContext = createContext<PageContextProps | undefined>(
  undefined
);

export const PageProvider: React.FC<
  PageContextProps & { children: ReactNode }
> = ({ pageID, pageUrl, sitewideBanner, hideNavigation, children }) => {
  const contextValue = useMemo(
    () => ({ pageID, pageUrl, sitewideBanner, hideNavigation }),
    [pageID, pageUrl, sitewideBanner, hideNavigation]
  );
  return (
    <PageContext.Provider value={contextValue}>{children}</PageContext.Provider>
  );
};

export const usePage = (): PageContextProps => {
  const context = useContext(PageContext);
  if (!context) {
    throw new Error('usePage must be used within a PageProvider');
  }
  return context;
};
