import React, { FC } from "react";
import { Icon, IconProps } from "../Icon";

export const Padlock: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M12 1C8.676 1 6 3.676 6 7v1c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2V7c0-3.324-2.676-6-6-6Zm0 2c2.276 0 4 1.724 4 4v1H8V7c0-2.276 1.724-4 4-4Zm0 10c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2Z"
    />
  </Icon>
);
