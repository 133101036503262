const media = {
  xSmall: '390px',
  small: '490px',
  medium: '768px',
  large: '992px',
  xLarge: '1390px',
} as const;

export default media;
export type ThemeMedia = typeof media;
export type ThemeMediaKeys = keyof typeof media;
