import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import {
  Footer as FooterComponent,
  FooterMenuProps,
} from '@zempler/theme/src/components/footer/Footer';

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      socialLinks: umbracoSocialLinksType {
        linkedIn
        facebook
        x: twitter
      }
      umbracoFooterType {
        navigationColumns {
          block {
            components {
              ... on UMBRACO_FooterMenuComponentType {
                header
                links {
                  ...Link
                }
              }
            }
          }
        }
        logos {
          image {
            ...FooterLogoImage
          }
        }
        smallText
      }
    }
  `);

  const smalltext = data.umbracoFooterType?.smallText;
  const navigationColumns =
    data.umbracoFooterType?.navigationColumns[0].block.components.map(
      (item) => {
        const navItem: FooterMenuProps = {
          heading: item.header,
          links: item.links,
        };
        return navItem;
      }
    );

  const { socialLinks } = data;

  return (
    <FooterComponent
      navigationColumns={navigationColumns}
      smallText={smalltext}
      socialLinks={socialLinks}
      logos={data.umbracoFooterType?.logos}
    />
  );
};

export default Footer;
