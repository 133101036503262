import React, { FC } from "react";
import { Icon, IconProps } from "../Icon";

export const ArrowRight: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M14 5.342c-.256 0-.512.1-.707.295l-.086.086a.999.999 0 0 0 0 1.414L17.07 11H4a1 1 0 0 0 0 2h13.07l-3.863 3.863a.999.999 0 0 0 0 1.414l.086.086a.999.999 0 0 0 1.414 0l5.656-5.656a.999.999 0 0 0 0-1.414l-5.656-5.656A.998.998 0 0 0 14 5.342Z"
    />
  </Icon>
);
