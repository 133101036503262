import React, { FC } from "react";
import { Icon, IconProps } from "../Icon";

export const Download: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M19.355 10.036A7.5 7.5 0 0 0 12 4a7.493 7.493 0 0 0-6.648 4.036A6 6 0 0 0 6 20h13a5 5 0 0 0 5-5c0-2.64-2.052-4.779-4.645-4.964ZM12 18l-4.333-4.333A.39.39 0 0 1 7.943 13H10V9.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5V13h2.057a.39.39 0 0 1 .276.667L12 18Z"
    />
  </Icon>
);
