const iconSizes = {
  small: '20 20',
  medium: '24 24',
  large: '32 32',
  xLarge: '48 48',
} as const;

export default iconSizes;
export type ThemeIconSizes = typeof iconSizes;
export type ThemeIconSizesKeys = keyof typeof iconSizes;
