import React, { FC } from "react";
import { Icon, IconProps } from "../Icon";

export const Smile: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2Zm3.5 6c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5-1.5-.67-1.5-1.5.67-1.5 1.5-1.5Zm-7 0c.83 0 1.5.67 1.5 1.5S9.33 11 8.5 11 7 10.33 7 9.5 7.67 8 8.5 8Zm3.5 9.5a5.484 5.484 0 0 1-4.747-2.747.505.505 0 0 1 .439-.753h8.615c.385 0 .632.42.439.753A5.48 5.48 0 0 1 12 17.5Z"
    />
  </Icon>
);
