// This file allows for the theme to be extended by the child theme.
import theme from '../theme';
import colorDefaults, { ThemeColors } from './colors';
import borderDefaults, { ThemeBorders } from './borders';
import borderRadiiDefaults, { ThemeBorderRadii } from './borderRadii';
import shadowDefaults, { ThemeShadows } from './shadows';
import gradientDefaults, { ThemeGradients } from './gradients';
import iconSizeDefaults, { ThemeIconSizes } from './iconSizes';
import layersDefaults, { ThemeLayers } from './layers';
import mediaDefaults, { ThemeMedia } from './media';
import maxMediaDefaults from './maxMedia';
import spaceDefaults, { ThemeSpace } from './space';
import typographyDefaults, { ThemeTypography } from './typography';
import buttonDefaults, { ThemeButtons } from './buttons';
import globalDefaults from './global';

export const colors: ThemeColors = {
  ...colorDefaults,
  ...(theme.colors || {}),
};
export const borders: ThemeBorders = {
  ...borderDefaults,
  ...(theme.borders || {}),
};
export const borderRadii: ThemeBorderRadii = {
  ...borderRadiiDefaults,
  ...(theme.borderRadii || {}),
};
export const shadows: ThemeShadows = {
  ...shadowDefaults,
  ...(theme.shadows || {}),
};
export const gradients: ThemeGradients = {
  ...gradientDefaults,
  ...(theme.gradients || {}),
};
export const iconSizes: ThemeIconSizes = {
  ...iconSizeDefaults,
  ...(theme.iconSizes || {}),
};
export const layers: ThemeLayers = {
  ...layersDefaults,
  ...(theme.layers || {}),
};
export const media: ThemeMedia = { ...mediaDefaults, ...(theme.media || {}) };
export const maxMedia: ThemeMedia = {
  ...maxMediaDefaults,
  ...(theme.maxMedia || {}),
};
export const space: ThemeSpace = { ...spaceDefaults, ...(theme.space || {}) };
export const buttons: ThemeButtons = {
  ...buttonDefaults,
  ...(theme.buttons || {}),
};
export const typography: ThemeTypography = {
  ...typographyDefaults,
  ...(theme.typography || {}),
};
export const global = { ...globalDefaults, ...(theme.global || {}) };

export default {
  colors,
  borders,
  borderRadii,
  shadows,
  gradients,
  iconSizes,
  global,
  layers,
  media,
  maxMedia,
  space,
  typography,
  buttons,
};
