import React, { FC } from "react";
import { Icon, IconProps } from "../Icon";

export const Tick: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M19 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2Zm-8.293 13.707a.999.999 0 0 1-1.414 0L6 13.414A1 1 0 0 1 7.414 12L10 14.586 16.586 8A1 1 0 0 1 18 9.414l-7.293 7.293Z"
    />
  </Icon>
);
