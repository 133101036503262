import React, { FC } from "react";
import { Icon, IconProps } from "../Icon";

export const Address: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M13.291 2.02a1.99 1.99 0 0 0-1.412.566l-3.522 3.52c-.493.492-1.343.498-1.85.025a1 1 0 1 0-1.44 1.385l-.005.004c.018.017.04.029.06.046l.034.03c1.27 1.172 3.388 1.153 4.615-.076l3.543-3.499 1.248 1.174S4.992 14.782 4.338 15.42c-.655.638-.813 1.435-.813 1.435l-.617 3.592c-.036.473.176.694.656.643l3.551-.61s.833-.178 1.377-.728 12.63-12.63 12.63-12.63A2.98 2.98 0 0 0 22 5c0-.801-.313-1.555-.879-2.121-1.133-1.134-3.11-1.133-4.242 0l-.902.902-1.27-1.195a1.998 1.998 0 0 0-1.416-.566ZM19 4.01A.987.987 0 0 1 20 5a.993.993 0 0 1-.293.707l-.816.816-1.457-1.37.859-.86A.999.999 0 0 1 19 4.01ZM7.086 15.502l1.412 1.412-1.566 1.568-1.707.293.293-1.707 1.568-1.566Z"
    />
  </Icon>
);
