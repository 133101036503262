import { GatsbyImage, getImage, ImageDataLike } from 'gatsby-plugin-image';
import React, { FC } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

// Interface

export interface ImageProps {
  alt?: string;
  loading?: 'lazy' | 'eager' | undefined;
  src?: ImageDataLike | string;
  onClick?: () => any;
  roundedCorners?: boolean;
  border?: boolean;
}

interface StyledImageProps {
  roundedCorners?: boolean;
  border?: boolean;
}

export const StyledImage = styled.div<StyledImageProps>`
  ${({ theme: { borderRadii, borders }, roundedCorners, border }) => css`
    ${roundedCorners &&
    css`
      border-radius: ${borderRadii.small};
      overflow: hidden;
      isolation: isolate;
    `}

    ${border &&
    css`
      border: ${borders.darkBlue};
    `}

    img {
      width: 100%;
    }
  `};
`;

// JSX

export const Image: FC<ImageProps> = ({
  src,
  alt,
  loading,
  roundedCorners,
  border,
  ...rest
}) => {
  // if no source

  if (!src) {
    return null;
  }

  // If the image is a simple string just return an img tag

  if (typeof src === 'string') {
    return (
      <StyledImage roundedCorners={roundedCorners} border={border} {...rest}>
        <img src={src} alt={alt ?? ''} />
      </StyledImage>
    );
  }

  // else see if we can get the Gatstby image

  const imageData = getImage(src);

  if (!imageData) {
    return null;
  }

  return (
    <StyledImage roundedCorners={roundedCorners} border={border} {...rest}>
      <GatsbyImage
        image={imageData}
        alt={alt ?? ''}
        loading={loading}
        {...rest}
      />
    </StyledImage>
  );
};

export default Image;
