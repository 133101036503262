module.exports = [{
      plugin: require('../../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","icons":[{"src":"/src/images/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/src/images/icon-512x512.png","sizes":"512x512","type":"image/png","purpose":"any maskable"}],"name":"Zempler","short_name":"Zempler","start_url":"/","background_color":"#80D9FF","theme_color":"#80D9FF","display":"minimal-ui","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"3fa101eacab82c7e9fcbbd3fcbf63c4e"},
    },{
      plugin: require('../../../themes/zempler/gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../themes/brochureware/gatsby-browser.tsx'),
      options: {"plugins":[],"companyName":"Zempler","graphqlUrl":"https://cms-live.zemplerbank.com/graphql","siteId":"2284"},
    },{
      plugin: require('../../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
