import React, { FC } from "react";
import { Icon, IconProps } from "../Icon";

export const Pound: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M17 19H8.854c.486-.726.734-1.685.734-2.888L9.53 13.75h3.6c.48 0 .87-.39.87-.87v-.01a.87.87 0 0 0-.87-.87H9.424l-.112-3.721c0-1.009.272-1.825.815-2.447.544-.622 1.254-.932 2.132-.932.927 0 1.656.251 2.187.752.361.342.653 1.09.764 1.444.168.537.535.904 1.13.904.656 0 1.188-.532 1.188-1.188 0-.109-.048-.447-.048-.447a4.155 4.155 0 0 0-1.235-2.083C15.318 3.428 14.06 3 12.472 3c-1.671 0-3.019.479-4.043 1.438-1.024.96-1.535 2.242-1.535 3.846L7.004 12H5.87a.87.87 0 0 0-.87.87v.01c0 .48.39.87.87.87h1.211l.088 2.374v.097c-.017.774-.13 1.425-.338 1.953-.169.426-.381.697-.631.826H6a1 1 0 0 0 0 2h11a1 1 0 0 0 0-2Z"
    />
  </Icon>
);
