import React, { FC } from "react";
import { Icon, IconProps } from "../Icon";

export const Help: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2Zm.5 17h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5Zm2.57-7.75-.9.92C13.45 12.9 13 13.5 13 15h-2v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26a1.975 1.975 0 0 0 .553-1.803 1.994 1.994 0 0 0-1.389-1.526 2.008 2.008 0 0 0-2.466 1.272c-.128.371-.44.647-.833.647h-.291c-.581 0-.987-.564-.825-1.122a4.003 4.003 0 0 1 3.225-2.831c1.524-.235 2.969.549 3.873 1.799 1.18 1.632.832 3.385-.187 4.404Z"
    />
  </Icon>
);
