/**
 * Replaces the site URL in the given string with an empty string.
 *
 * @param {string} content - The string in which the URL needs to be replaced.
 * @returns {string} - The modified string with the URL replaced.
 */
export const replaceSiteUrl = (content: string) =>
  content && typeof content === 'string'
    ? content.replace(
        /(http|https):\/\/(localhost:\d+|hatch.gatsbyjs.io)/gi,
        ''
      )
    : '';
