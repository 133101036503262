import React, { createContext, useState, useContext, useEffect } from 'react';

// Create a context to store query string, referrer, and cookies
const QueryReferrerContext = createContext<{
  queryString: string | null;
  referrer: string | null;
  setQueryString: React.Dispatch<React.SetStateAction<string | null>>;
  setReferrer: React.Dispatch<React.SetStateAction<string | null>>;
}>({
  queryString: null,
  referrer: null,
  setQueryString: () => {},
  setReferrer: () => {},
});

// Custom hook to use the context
export const useQueryReferrer = () => useContext(QueryReferrerContext);

// Context provider component
export const QueryReferrerProvider: React.FC = ({ children }) => {
  const [queryString, setQueryString] = useState<string | null>(null);
  const [referrer, setReferrer] = useState<string | null>(null);

  // Function to set cookie
  const setCookie = (name: string, value: string, path: string) => {
    document.cookie = `${name}=${value};path=${path}`;
  };

  // Function to get cookie
  const getCookie = (name: string) => {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim().split('=');
      if (cookie[0] === name) {
        return cookie[1];
      }
    }
    return null;
  };

  const extractQueryParams = (url: string): string => {
    // Parse the URL
    const parsedUrl = new URL(url);

    // Get the query string
    const queryString = parsedUrl.search;

    // Remove the leading '?' character
    const queryParams = queryString.substring(1);

    return queryParams;
  };

  // Logic to set query string and referrer when the component mounts
  useEffect(() => {
    const urlQueryString = extractQueryParams(window.location.href);
    const { referrer } = document;

    if (urlQueryString) {
      setQueryString(urlQueryString);
      if (!getCookie('qs')) {
        setCookie('qs', urlQueryString, '/');
      }
    } else {
      const qsCookie = getCookie('qs');
      if (qsCookie) {
        setQueryString(qsCookie);
      }
    }

    if (referrer && referrer !== 'undefined' && referrer !== '') {
      setReferrer(referrer);
      if (!getCookie('referrer')) {
        setCookie('referrer', referrer, '/');
      }
    } else {
      const referrerCookie = getCookie('referrer');
      if (referrerCookie) {
        setReferrer(referrerCookie);
      }
    }
  }, []);

  return (
    <QueryReferrerContext.Provider
      value={{ queryString, referrer, setQueryString, setReferrer }}
    >
      {children}
    </QueryReferrerContext.Provider>
  );
};
