import { ThemeColorsKeys } from "./colors";

interface ThemeButton {
  background: ThemeColorsKeys;
}
const buttons = {
  neutral: {
    background: "neutral",
  },
  zemplerBlue: {
    background: "zemplerBlue",
  },
  lime: {
    background: "lime",
  },
  neutralOutline: {
    background: "darkBlue",
  },
} as const;

export default buttons;

export type ThemeButtonKeys = keyof typeof buttons;
export type ThemeButtons = Record<ThemeButtonKeys, ThemeButton>;
