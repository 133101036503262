import React, { FC, Ref, forwardRef } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ThemeSpaceKeys } from '../../styles/tokens/space';

export interface StackProps
  extends React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>> {
  gap?: ThemeSpaceKeys;
  as?: string | React.ElementType;
}

const StyledStack = styled.div<{ gap?: ThemeSpaceKeys; asGrid?: boolean }>`
  ${({ theme: { space }, gap }) => css`
    > * + * {
      margin-top: ${space[gap || 'fluidSmallToMedium']};
    }
  `};
`;
// Your existing Stack component
const StackComponent = (
  { children, gap, as, ...rest }: StackProps,
  ref: Ref<HTMLDivElement>
) => {
  const StyledStackAs = as
    ? StyledStack.withComponent(as as React.ComponentType<any>)
    : StyledStack;
  return (
    <StyledStackAs ref={ref} gap={gap} {...rest}>
      {children}
    </StyledStackAs>
  );
};

// Wrap the existing Stack component with forwardRef
const Stack = forwardRef<HTMLDivElement, StackProps>(StackComponent);

export { Stack, StyledStack };
Stack.displayName = 'Stack';
export default Stack;
