import { isBrowser } from './isBrowser';

declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}
/**
 * Pushes data to the Google Tag Manager data layer, if it is defined in the window object.
 * @param {object} data - An object containing key-value pairs to be pushed to the data layer.
 */
export const dataLayerPush = (data: Record<string, any>) => {
  if (isBrowser && window.dataLayer) {
    window.dataLayer.push(data);
  }
};
