import React, { FC } from "react";
import { Icon, IconProps } from "../Icon";

export const Email: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M4 4c-1.024 0-1.86.773-1.977 1.766L12 12l9.977-6.234A1.993 1.993 0 0 0 20 4H4ZM2 7.752V18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V7.752l-9.47 5.918a1 1 0 0 1-1.06 0L2 7.752Z"
    />
  </Icon>
);
