import React, { FC } from "react";
import { Icon, IconProps } from "../Icon";

export const X: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M4.8 3.2a1.6 1.6 0 0 0-1.6 1.6v14.4a1.6 1.6 0 0 0 1.6 1.6h14.4a1.6 1.6 0 0 0 1.6-1.6V4.8a1.6 1.6 0 0 0-1.6-1.6H4.8Zm2.119 4h3.689l2.153 3.078L15.425 7.2h1.16l-3.302 3.825 4.04 5.775h-3.689l-2.389-3.416-2.95 3.416h-1.18l3.605-4.164L6.92 7.2Zm1.784.947 5.403 7.701h1.431l-5.404-7.701h-1.43Z"
    />
  </Icon>
);
