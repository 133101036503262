import { primaryColors } from './primary';
import { secondaryColors } from './secondary';
import { systemColors } from './system';
import { utilityColors } from './utility';

// Main colours

export const colors = {
  transparent: 'transparent',
  ...primaryColors,
  ...secondaryColors,
  ...systemColors,
  ...utilityColors,
} as const;

export default colors;

export type ThemeColors = typeof colors;
export type ThemeColorsKeys = keyof typeof colors;
