import React, { FC } from "react";
import { Icon, IconProps } from "../Icon";

export const ArrowLeft: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M9.657 5.342c.255 0 .512.1.707.295l.085.086a.999.999 0 0 1 0 1.414L6.587 11h13.07a1 1 0 0 1 0 2H6.587l3.864 3.863a.999.999 0 0 1 0 1.414l-.086.086a.999.999 0 0 1-1.415 0l-5.656-5.656a.999.999 0 0 1 0-1.414L8.95 5.637a.998.998 0 0 1 .708-.295Z"
    />
  </Icon>
);
