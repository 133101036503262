import React, { FC } from "react";
import { Icon, IconProps } from "../Icon";

export const Percentage: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M7.5 4A3.515 3.515 0 0 0 4 7.5C4 9.421 5.579 11 7.5 11S11 9.421 11 7.5 9.421 4 7.5 4Zm8.48.988a1 1 0 0 0-.812.457l-8 12a1 1 0 1 0 1.664 1.11l8-12a1 1 0 0 0-.852-1.567ZM7.5 6C8.34 6 9 6.66 9 7.5S8.34 9 7.5 9 6 8.34 6 7.5 6.66 6 7.5 6Zm9 7a3.515 3.515 0 0 0-3.5 3.5c0 1.921 1.579 3.5 3.5 3.5s3.5-1.579 3.5-3.5-1.579-3.5-3.5-3.5Zm0 2c.84 0 1.5.66 1.5 1.5s-.66 1.5-1.5 1.5-1.5-.66-1.5-1.5.66-1.5 1.5-1.5Z"
    />
  </Icon>
);
