const convertToRem = (value: string | number): number => {
  if (typeof value === 'string' && value.endsWith('rem')) {
    return parseFloat(value);
  }
  if (typeof value === 'number') {
    return value / 16;
  }
  throw new Error(`Invalid font size: ${value}`);
};

export const getClampValue = (
  minFontSize: string | number,
  maxFontSize: string | number,
  minViewport = 392,
  maxViewport = 1440
): string => {
  const minFs = convertToRem(minFontSize);
  const maxFs = convertToRem(maxFontSize);
  const minVw = convertToRem(minViewport);
  const maxVw = convertToRem(maxViewport);

  const factor = (1 / (maxVw - minVw)) * (maxFs - minFs);
  const calcRem = (minFs - minVw * factor).toFixed(4);
  const calcVw = (100 * factor).toFixed(4);

  const min = Math.min(minFs, maxFs);
  const max = Math.max(minFs, maxFs);

  return `clamp(${min}rem, ${calcRem}rem + ${calcVw}vw, ${max}rem)`;
};
