/** Converts pixel values to rem values.
 *
 * @param {number} pixels - The pixel value to be converted to rem.
 * @param {number} baseFontSize - The base font size in pixels. Default is 16px.
 * @returns {string} - The equivalent rem value as a string.
 */
export const pxToRem = (pixels: number, baseFontSize = 16): string => {
  const remValue = pixels / baseFontSize;
  return `${remValue}rem`;
};
