import React, { FC } from "react";
import { Icon, IconProps } from "../Icon";

export const SquarePlus: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.667 3.186a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-14a2 2 0 0 0-2-2h-14Zm1.05 10.05h4.9v4.9c0 .297.102.546.306.748.204.2.454.302.75.302.295 0 .543-.101.743-.302.2-.202.3-.451.3-.748v-4.9h4.9c.298 0 .547-.103.749-.307.2-.204.302-.454.302-.75 0-.295-.101-.543-.302-.743-.202-.2-.451-.3-.748-.3h-4.9v-4.9c0-.298-.103-.547-.307-.749a1.028 1.028 0 0 0-.75-.301c-.295 0-.543.1-.743.301-.2.202-.3.451-.3.749v4.9h-4.9c-.298 0-.547.102-.749.306a1.027 1.027 0 0 0-.301.75c0 .296.1.543.301.743.202.2.451.3.748.3Z"
      clipRule="evenodd"
    />
  </Icon>
);
