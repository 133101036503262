import { colors } from './colors';

const borders = {
  darkBlue: `solid 1px ${colors.darkBlue}`,
  white: `solid 1px ${colors.white}`,
} as const;

export default borders;

export type ThemeBorders = typeof borders;
export type ThemeBordersKeys = keyof typeof borders;
