import React from "react";
import Header from "./Header";
import Footer from "./Footer";

export const BaseLayout = ({ children }: React.PropsWithChildren) => (
  <>
    <Header />
    <main>{children}</main>
    <Footer />
  </>
);
