import React, { FC } from "react";
import { Icon, IconProps } from "../Icon";

export const Idea: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M11.984-.014A1 1 0 0 0 11 1v1a1 1 0 1 0 2 0V1a1.001 1.001 0 0 0-1.016-1.014ZM4.211 3.211a1 1 0 0 0-.695 1.719l.707.707a1 1 0 1 0 1.414-1.414l-.707-.707a1 1 0 0 0-.72-.305Zm15.547.002a1 1 0 0 0-.688.303l-.707.707a1 1 0 1 0 1.414 1.414l.707-.707a1 1 0 0 0-.726-1.717ZM12 5c-3.86 0-7 3.14-7 7 0 2.785 1.639 5.189 4 6.314V21c0 1.103.897 2 2 2h2c1.103 0 2-.897 2-2v-2.686c2.361-1.125 4-3.529 4-6.314 0-3.86-3.14-7-7-7ZM1 11a1 1 0 1 0 0 2h1a1 1 0 1 0 0-2H1Zm21 0a1 1 0 1 0 0 2h1a1 1 0 1 0 0-2h-1ZM4.91 18.06a1 1 0 0 0-.687.303l-.707.707a1 1 0 1 0 1.414 1.414l.707-.707a1 1 0 0 0-.727-1.716Zm14.15 0a1 1 0 0 0-.697 1.717l.707.707a1 1 0 1 0 1.414-1.414l-.707-.707a1 1 0 0 0-.716-.302Z"
    />
  </Icon>
);
