import { GatsbyBrowser } from 'gatsby';

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = ({ location }) => {
  const eventData = {
    pathname: location.pathname.replace(/\//g, ''),
  };
  const event = new CustomEvent('pageChange', { detail: eventData });
  window.dispatchEvent(event);
};

export { wrapPageElement } from './gatsby-shared';

export const onClientEntry: GatsbyBrowser['onClientEntry'] = () => {

};
