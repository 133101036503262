import { pxToRem, getClampValue } from '../../utils';

const staticSpacing = {
  none: '0px',
  xxxxxSmall: pxToRem(4),
  xxxxSmall: pxToRem(6),
  xxxSmall: pxToRem(8),
  xxSmall: pxToRem(12),
  xSmall: pxToRem(16),
  small: pxToRem(24),
  medium: pxToRem(32),
  large: pxToRem(48),
  xLarge: pxToRem(56),
  xxLarge: pxToRem(72),
  xxxLarge: pxToRem(92),
  xxxxLarge: pxToRem(120),
  xxxxxLarge: pxToRem(148),
} as const;

const fluidSpacing = {
  fluidXxxxxSmallToXxxSmall: getClampValue(
    staticSpacing.xxxxxSmall,
    staticSpacing.xxxSmall
  ), // 4px => 8px
  fluidXxxxSmallToXxxSmall: getClampValue(
    staticSpacing.xxxxSmall,
    staticSpacing.xxxSmall
  ), // 6px => 8px
  fluidXxxSmallToXxSmall: getClampValue(
    staticSpacing.xxxSmall,
    staticSpacing.xxSmall
  ), // 8px => 12px
  fluidXxxSmallToXSmall: getClampValue(
    staticSpacing.xxxSmall,
    staticSpacing.xSmall
  ), // 8px => 16px
  fluidXxSmallToxSmall: getClampValue(
    staticSpacing.xxSmall,
    staticSpacing.xSmall
  ), // 12px => 16px
  fluidXxSmallToMedium: getClampValue(
    staticSpacing.xxSmall,
    staticSpacing.medium
  ), // 12px => 32px
  fluidXSmallToSmall: getClampValue(staticSpacing.xSmall, staticSpacing.small), // 16px => 24px
  fluidXSmallToMedium: getClampValue(
    staticSpacing.xSmall,
    staticSpacing.medium
  ), // 16px => 32px
  fluidXSmallToLarge: getClampValue(staticSpacing.xSmall, staticSpacing.large), // 16px => 48px
  fluidSmallToMedium: getClampValue(staticSpacing.small, staticSpacing.medium), // 24px => 32px
  fluidSmallToLarge: getClampValue(staticSpacing.small, staticSpacing.large), // 24px => 48px
  fluidSmallToXLarge: getClampValue(staticSpacing.small, staticSpacing.xLarge), // 24px => 56px
  fluidSmallToXxLarge: getClampValue(
    staticSpacing.small,
    staticSpacing.xxLarge
  ), // 24px => 72px
  fluidSmallToXxxlarge: getClampValue(
    staticSpacing.small,
    staticSpacing.xxxLarge
  ), // 24px => 92px
  fluidMediumToLarge: getClampValue(staticSpacing.medium, staticSpacing.large), // 32px => 48px
  fluidMediumToXLarge: getClampValue(
    staticSpacing.medium,
    staticSpacing.xLarge
  ), // 32px => 56px
  fluidMediumToXxLarge: getClampValue(
    staticSpacing.medium,
    staticSpacing.xxLarge
  ), // 32px => 72px
  fluidLargeToXLarge: getClampValue(staticSpacing.large, staticSpacing.xLarge), // 48px => 56px
  fluidLargeToXxLarge: getClampValue(
    staticSpacing.large,
    staticSpacing.xxLarge
  ), // 48px => 72px
  fluidLargeToXxxxxLarge: getClampValue(
    staticSpacing.large,
    staticSpacing.xxxxxLarge
  ), // 48px => 148px
  fluidXxLargeToXxxLarge: getClampValue(
    staticSpacing.xxLarge,
    staticSpacing.xxxLarge
  ), // 72px => 92px
} as const;

export const space = {
  ...staticSpacing,
  ...fluidSpacing,
} as const;

export default space;
export type ThemeSpace = typeof space;
export type ThemeSpaceKeys = keyof typeof space;
