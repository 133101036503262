import React, { FC, useEffect } from 'react';
import { PageProps } from 'gatsby';
import { useDataLayerPush } from '@zempler/theme/src/hooks/useDataLayerPush';

export interface BrochurewareDataLayerWrapperProps {
  children: any;
  data: PageProps['data'];
  serverData?: PageProps['serverData'];
  location?: PageProps['location'];
  pageContext: PageProps['pageContext'];
}

export const BrochurewareDataLayerWrapper: FC<
  React.PropsWithChildren<BrochurewareDataLayerWrapperProps>
> = ({ children, data, pageContext, serverData, location }) => {
  const { dataLayerPush } = useDataLayerPush();

  useEffect(() => {
    dataLayerPush({
      event: 'gatsby_pageview',
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return children;
};
