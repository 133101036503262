import React, { FC } from "react";
import { Icon, IconProps } from "../Icon";

export const Menu: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M3 5a1 1 0 1 0 0 2h18a1 1 0 1 0 0-2H3Zm0 6a1 1 0 1 0 0 2h18a1 1 0 1 0 0-2H3Zm0 6a1 1 0 1 0 0 2h18a1 1 0 1 0 0-2H3Z"
    />
  </Icon>
);
