import React, { FC } from "react";
import { Icon, IconProps } from "../Icon";

export const ChevronDown: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M12 14.071 8.179 10.25a1.061 1.061 0 0 0-1.5 1.5l4.614 4.614a.999.999 0 0 0 1.414 0l4.614-4.614a1.061 1.061 0 0 0-1.5-1.5L12 14.071Z"
    />
  </Icon>
);
