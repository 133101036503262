import React, { FC } from "react";
import { Icon, IconProps } from "../Icon";

export const Alert: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M22.239 18.451 13.442 3.816A1.669 1.669 0 0 0 12 3c-.596 0-1.135.305-1.441.815L1.761 18.451A1.684 1.684 0 0 0 3.203 21h17.595a1.683 1.683 0 0 0 1.441-2.549ZM12.5 18h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5Zm-.5-4a1 1 0 0 1-1-1v-3a1 1 0 0 1 2 0v3a1 1 0 0 1-1 1Z"
    />
  </Icon>
);
