import React from "react";
import parse, { HTMLReactParserOptions, Element } from "html-react-parser";
import { replaceSiteUrl } from "./replaceSiteUrl";
import { isBrowser } from "./isBrowser";

/**
 * Obfuscate an email address.
 *
 * @param {string} email - The email address to be obfuscated.
 * @returns {string} - The obfuscated email address.
 */
const obfuscateEmail = (email) => {
  return email
    .split("")
    .map((char) => `&#${char.charCodeAt(0)};`)
    .join("");
};

/**
 * De-obfuscate an email address.
 *
 * @param {string} obfuscatedEmail - The obfuscated email address.
 * @returns {string} - The de-obfuscated email address.
 */
const deobfuscateEmail = (obfuscatedEmail) => {
  return obfuscatedEmail.replace(/&#(\d+);/g, (match, dec) =>
    String.fromCharCode(dec)
  );
};

const options: HTMLReactParserOptions = {
  replace: (domNode) => {
    if (domNode instanceof Element && domNode.name === "a") {
      const href = domNode.attribs.href;

      if (href && href.startsWith("mailto:")) {
        if (isBrowser) {
          const email = href.slice(7); // Get the email address without 'mailto:'
          const obfuscatedEmail = obfuscateEmail(email);

          // Set the obfuscated email in the href
          domNode.attribs.href = `mailto:${obfuscatedEmail}`;

          // Add onClick handler to de-obfuscate and navigate correctly
          domNode.attribs.onClick = (event) => {
            event.preventDefault();
            const unobfuscatedHref = `mailto:${deobfuscateEmail(
              obfuscatedEmail
            )}`;
            window.location.href = unobfuscatedHref;
          };
        }
      }
    }
  },
};

type markupArg = string | string[];

/**
 * Takes in HTML as input and returns parsed HTML output.
 *
 * @param {markupArg} html - The HTML to be parsed.
 * @returns {string|null} - The parsed HTML output or null if input is invalid.
 */
export const createMarkup = (html: markupArg) => {
  let checkHtml = html;
  if (!checkHtml) {
    return null;
  }
  if (Array.isArray(checkHtml)) {
    checkHtml = checkHtml.join("");
  }
  if (typeof checkHtml !== "string") {
    return null;
  }
  const videoWrapperDiv = {
    start: '<div class="video-wrapper">',
    end: "</div>",
  };
  const iframeRegex = /<iframe.*?<\/iframe>/g;

  const output: string = checkHtml.replace(
    iframeRegex,
    (match) => videoWrapperDiv.start + match + videoWrapperDiv.end
  );

  return parse(replaceSiteUrl(output), options);
};
