export const getUrlParts = (url: string | undefined) => {
  let clickHostname;
  let clickPath;
  let clickQueryString;

  if (!url) {
    return {
      clickPath,
      clickHostname,
      clickQueryString,
    };
  }

  if (url?.startsWith('/')) {
    clickHostname = 'www.zemplerbank.com';
    clickPath = url;
  } else if (url?.startsWith('http') || url?.startsWith('www')) {
    const absoluteUrl = new URL(url);
    clickHostname = absoluteUrl.hostname;
    clickPath = absoluteUrl.pathname;
    clickQueryString = absoluteUrl.search;
  } else {
    clickHostname = undefined;
    clickPath = url;
    clickQueryString = undefined;
  }

  return {
    clickPath,
    clickHostname,
    clickQueryString,
  };
};
