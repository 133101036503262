import React from "react";
import { Global, css } from "@emotion/react";
import { useBackgroundColor } from "../hooks/useBackgroundColor";
import { useResponsiveType } from "../hooks/useResponsiveType";
import { colors, media } from "./tokens";

export const GlobalStyle = () => (
  <Global
    styles={css`
      main {
        padding-top: var(--header-height);

        @media (min-width: ${media.large}) {
          padding-top: 0;
        }
      }

      ::selection {
        background: ${colors.zemplerBlue};
        color: ${colors.darkBlue};
      }

      *,
      *::before,
      *::after {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        border: 0;
      }

      *:focus-visible {
        outline: 3px solid ${colors.yellow};
      }

      html,
      body {
        height: 100%;
      }

      body {
        -webkit-font-smoothing: antialiased;
      }

      img,
      picture,
      video,
      canvas,
      svg {
        display: block;
        max-width: 100%;
      }

      input,
      button,
      textarea,
      select {
        font: inherit;
      }

      p,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        overflow-wrap: break-word;
      }

      #root,
      #__next {
        isolation: isolate;
      }

      ul,
      ol {
        list-style: none;
      }

      a {
        color: currentColor;
      }

      a[href^="tel:"] {
        text-decoration: none;
      }

      address {
        font-style: normal;
      }

      button {
        cursor: pointer;
        background: none;
        color: currentColor;
      }

      // Selection

      ::selection {
      }

      // Body

      body {
        ${useResponsiveType("bodyMedium")}
        ${useBackgroundColor("white")}
        min-height: 100vh;
        width: 100%;
        min-width: 320px;
      }
    `}
  />
);

export default GlobalStyle;
