/**
 * Converts the input string into a valid ID string by replacing spaces with dashes,
 * removing special characters, and converting to lowercase.
 *
 * @param {string} input - The input string to be converted.
 * @returns {string} - The converted string suitable for use as an ID.
 */
export const formatForID = (input: string): string => 
    input
      .trim() // Trim leading and trailing whitespace
      .toLowerCase() // Convert to lowercase
      .replace(/['"“”‘’]/g, '') // Remove quotes
      .replace(/[^a-z0-9\s-]/g, '') // Remove special characters except spaces and dashes
      .replace(/\s+/g, '-'); // Replace spaces with dashes
  
  // Example usage:
  // const idString = formatForID("This is a Test: Heading");
  // console.log(idString); // Output: "this-is-a-test-heading"
  