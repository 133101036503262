import { useCallback } from 'react';
import { isBrowser } from '../utils/isBrowser';

interface useDataLayerPushReturn {
  dataLayerPush: any;
}

export const useDataLayerPush = (): useDataLayerPushReturn => {
  const dataLayerPush = useCallback((data: Record<string, any>) => {
    if (isBrowser && window.dataLayer) {
      window.dataLayer.push({
        ...data,
      });
    }
  }, []);

  return {
    dataLayerPush,
  };
};
