import { getClampValue, pxToRem } from '../../utils';

const fontFamily = {
  heading: `'National2NarrowBold', 'National2NarrowBold Fallback'`,
  secondaryHeadingMedium: `'JordanaSansItalicMedium', 'JordanaSansItalicMedium Fallback'`,
  secondaryHeadingBold: `'JordanaSansItalicBold', 'JordanaSansItalicBold Fallback'`,
  body: '"DM Sans"',
  body400Normal: `'DmSans400Normal', 'DmSans400Normal Fallback'`,
  body400Italic: `'DmSans400Italic', 'DmSans400Italic Fallback'`,
  body500Normal: `'DmSans500Normal', 'DmSans500Normal Fallback'`,
  body500Italic: `'DmSans500Italic', 'DmSans500Italic Fallback'`,
  body700Normal: `'DmSans700Normal', 'DmSans700Normal Fallback'`,
  body700Italic: `'DmSans700Italic', 'DmSans700Italic Fallback'`,
};

export const fontWeight = {
  regular: 400,
  medium: 500,
  bold: 700,
};

export interface ThemeType {
  fontFamily?: string;
  fontWeight?: number;
  fontSize?: string;
  lineHeight?: string;
}

// Heading

const headingBase = {
  fontWeight: fontWeight.bold,
  fontFamily: fontFamily.heading,
};

const heading = {
  headingXLarge: {
    fontSize: getClampValue(56, 84),
    lineHeight: getClampValue(52, 80),
    ...headingBase,
  },
  headingLarge: {
    fontSize: getClampValue(48, 72),
    lineHeight: getClampValue(47, 68),
    ...headingBase,
  },
  headingMedium: {
    fontSize: getClampValue(36, 56),
    lineHeight: getClampValue(37, 58),
    ...headingBase,
  },
  headingSmall: {
    fontSize: getClampValue(26, 36),
    lineHeight: getClampValue(28, 42),
    ...headingBase,
  },
  headingXSmall: {
    fontSize: getClampValue(22, 28),
    lineHeight: getClampValue(24, 32),
    ...headingBase,
  },
  headingXxSmall: {
    fontSize: getClampValue(18, 32),
    lineHeight: getClampValue(28, 32),
    ...headingBase,
  },
} as const;

// Secondary heading

const secondaryHeadingBase = {
  fontFamily: fontFamily.secondaryHeading,
};

const secondaryHeading = {
  secondaryHeadingMediumBold: {
    fontSize: pxToRem(32),
    lineHeight: pxToRem(34),
    fontWeight: fontWeight.bold,
    fontFamily: fontFamily.secondaryHeadingBold,
  },
  secondaryHeadingMedium: {
    fontSize: pxToRem(32),
    lineHeight: pxToRem(34),
    fontWeight: fontWeight.medium,
    fontFamily: fontFamily.secondaryHeadingMedium,
  },
  secondaryHeadingSmallBold: {
    fontSize: pxToRem(24),
    lineHeight: pxToRem(25),
    fontWeight: fontWeight.bold,
    fontFamily: fontFamily.secondaryHeadingBold,
  },
  secondaryHeadingSmall: {
    fontSize: pxToRem(24),
    lineHeight: pxToRem(25),
    fontWeight: fontWeight.medium,
    fontFamily: fontFamily.secondaryHeadingMedium,
  },
  secondaryHeadingSmallToMedium: {
    fontSize: getClampValue(24, 32),
    lineHeight: getClampValue(25, 34),
    fontWeight: fontWeight.medium,
    fontFamily: fontFamily.secondaryHeadingMedium,
  },
  secondaryHeadingSmallToMediumBold: {
    fontSize: getClampValue(24, 32),
    lineHeight: getClampValue(25, 34),
    fontWeight: fontWeight.bold,
    fontFamily: fontFamily.secondaryHeadingBold,
  },
} as const;

// Body

const bodyBase = {
  fontSize: pxToRem(16),
  lineHeight: pxToRem(24),
};

const bodyLargeBase = {
  fontSize: pxToRem(18),
  lineHeight: pxToRem(24),
};

const bodyMediumBase = {
  fontSize: pxToRem(16),
  lineHeight: pxToRem(24),
};

const bodySmallBase = {
  fontSize: pxToRem(14),
  lineHeight: pxToRem(18),
};

const body = {
  body: {
    fontWeight: fontWeight.regular,
    fontFamily: fontFamily.body400Normal,
    ...bodyBase,
  },
  bodyMedium: {
    fontWeight: fontWeight.medium,
    fontFamily: fontFamily.body500Normal,
    ...bodyBase,
  },
  bodyBold: {
    fontFamily: fontFamily.body700Normal,
    fontWeight: fontWeight.bold,
    ...bodyBase,
  },
  bodyBoldToLargeBold: {
    fontWeight: fontWeight.bold,
    fontFamily: fontFamily.body700Normal,
    fontSize: getClampValue(16, 18),
    lineHeight: getClampValue(24, 24),
  },
  bodyToLarge: {
    fontSize: getClampValue(16, 18),
    lineHeight: getClampValue(24, 24),
    fontFamily: fontFamily.body400Normal,
    fontWeight: fontWeight.regular,
  },
  bodyMediumToLarge: {
    fontSize: getClampValue(16, 18),
    lineHeight: getClampValue(24, 24),
    fontFamily: fontFamily.body400Normal,
    fontWeight: fontWeight.regular,
  },
  bodySmall: {
    fontWeight: fontWeight.regular,
    fontFamily: fontFamily.body400Normal,
    ...bodySmallBase,
  },
  bodySmallToBody: {
    fontSize: getClampValue(14, 16),
    lineHeight: getClampValue(18, 24),
    fontWeight: fontWeight.regular,
    fontFamily: fontFamily.body400Normal,
  },
  bodySmallToLarge: {
    fontSize: getClampValue(14, 18),
    lineHeight: getClampValue(18, 24),
    fontWeight: fontWeight.regular,
    fontFamily: fontFamily.body400Normal,
  },
  bodySmallMedium: {
    fontWeight: fontWeight.medium,
    fontFamily: fontFamily.body500Normal,
    ...bodySmallBase,
  },
  bodySmallBold: {
    fontWeight: fontWeight.bold,
    fontFamily: fontFamily.body700Normal,
    ...bodySmallBase,
  },
  bodySmallBoldToBodyBold: {
    fontWeight: fontWeight.bold,
    fontSize: getClampValue(14, 16),
    lineHeight: getClampValue(18, 24),
    fontFamily: fontFamily.body700Normal,
  },
  bodyLarge: {
    fontWeight: fontWeight.regular,
    fontFamily: fontFamily.body400Normal,
    ...bodyLargeBase,
  },
  bodyLargeMedium: {
    fontWeight: fontWeight.medium,
    fontFamily: fontFamily.body500Normal,
  },
  bodyLargeBold: {
    fontWeight: fontWeight.bold,
    fontFamily: fontFamily.body700Normal,
    ...bodyLargeBase,
  },
} as const;

const typography = {
  ...heading,
  ...secondaryHeading,
  ...body,
} as const;

export default typography;

export type ThemeTypographyKeys = keyof typeof typography;
export type ThemeTypography = Record<ThemeTypographyKeys, ThemeType>;
