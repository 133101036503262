import React, { FC } from "react";
import { Icon, IconProps } from "../Icon";

export const Close: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      d="M4.99023 3.99023C4.79129 3.99029 4.5969 4.04967 4.43189 4.16078C4.26688 4.27189 4.13875 4.42969 4.0639 4.614C3.98904 4.79832 3.97085 5.00077 4.01165 5.19547C4.05246 5.39017 4.1504 5.56828 4.29296 5.70703L10.5859 12L4.29296 18.293C4.19699 18.3851 4.12036 18.4955 4.06758 18.6176C4.0148 18.7397 3.98691 18.8712 3.98556 19.0042C3.9842 19.1373 4.00941 19.2692 4.0597 19.3924C4.10999 19.5156 4.18435 19.6275 4.27843 19.7216C4.37251 19.8156 4.48441 19.89 4.60759 19.9403C4.73076 19.9906 4.86274 20.0158 4.99578 20.0144C5.12882 20.0131 5.26025 19.9852 5.38238 19.9324C5.50451 19.8796 5.61488 19.803 5.70702 19.707L12 13.4141L18.293 19.707C18.3851 19.803 18.4955 19.8796 18.6176 19.9324C18.7397 19.9852 18.8712 20.0131 19.0042 20.0144C19.1372 20.0158 19.2692 19.9906 19.3924 19.9403C19.5156 19.89 19.6275 19.8156 19.7216 19.7216C19.8156 19.6275 19.89 19.5156 19.9403 19.3924C19.9906 19.2692 20.0158 19.1373 20.0144 19.0042C20.0131 18.8712 19.9852 18.7397 19.9324 18.6176C19.8796 18.4955 19.803 18.3851 19.707 18.293L13.4141 12L19.707 5.70703C19.8515 5.56657 19.9502 5.38573 19.9902 5.18821C20.0301 4.99069 20.0095 4.7857 19.931 4.60012C19.8524 4.41453 19.7196 4.257 19.55 4.14817C19.3804 4.03935 19.1819 3.98429 18.9805 3.99023C18.7207 3.99798 18.4741 4.10655 18.293 4.29297L12 10.5859L5.70702 4.29297C5.61383 4.19717 5.50239 4.12103 5.37927 4.06903C5.25616 4.01704 5.12387 3.99024 4.99023 3.99023Z"
      fill="currentColor"
    />
  </Icon>
);
