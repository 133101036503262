import React, { FC } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ThemeColorsKeys } from '../../styles/tokens/colors';

// Interface

export interface DividerProps {}

// Styles

export const StyledDivider = styled.hr<DividerProps>`
  ${({ theme: { colors } }) => css`
    border-width: 0px 0px 1px;
    border-style: solid;
    border-color: currentColor;
  `};
`;

// JSX

export const Divider: FC<DividerProps> = ({ ...rest }) => (
  <StyledDivider {...rest} />
);

export default Divider;
