import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { forwardRef, useContext } from 'react';
import { on } from 'events';
import { ThemeButtonKeys } from '../../styles/tokens/buttons';
import {
  ThemeTypographyKeys,
  fontWeight,
} from '../../styles/tokens/typography';
import { useBackgroundColor, useResponsiveType } from '../../hooks';
import { ArrowLeft, ArrowRight } from '../icon';
import { LinkTo } from '../linkTo/LinkTo';
import { useDataLayerPush } from '../../hooks/useDataLayerPush';
import { getUrlParts } from '../../utils';
import { SectionContext } from '../../context/SectionContext';

export type ButtonType = HTMLButtonElement | HTMLAnchorElement;

export interface ButtonProps
  extends Partial<Omit<HTMLButtonElement, 'children'>>,
    Partial<
      Omit<
        HTMLAnchorElement,
        'addEventListener' | 'removeEventListener' | 'children'
      >
    > {
  variant?: ThemeButtonKeys;
  label?: string;
  url?: string;
  as?: string;
  a11yTitle?: string;
  dataTest?: string;
  arrowRight?: boolean;
  arrowLeft?: boolean;
  iconRight?: React.ReactNode;
  iconLeft?: React.ReactNode;
  onClick?: React.MouseEventHandler<ButtonType>;
  children?: React.ReactNode | string;
  state?: any;
  size?: 'xxSmall' | 'small' | 'medium' | 'fluidSmallToMedium';
  disableClickTracking?: boolean;
  customDataLayerEvent?: object;
  dataLayerEvent?: string;
  dataLayerClickContext?: string;
}

export interface StyledButtonProps {
  variant?: ThemeButtonKeys;
  size?: 'small' | 'medium' | 'fluidSmallToMedium';
}

type ButtonTypes = React.ReactNode | React.PropsWithChildren<any> | string;

// Fixes the React does not recognize the `XXX` prop on a DOM element.
const BLACKLISTED_PROPS = ['as', 'dataTest', 'a11yTitle'];
export const StyledButton = styled('a', {
  shouldForwardProp: (propName) =>
    // If not in black list then return true
    !BLACKLISTED_PROPS?.includes(propName),
})<StyledButtonProps>`
  ${({ theme: { space, borderRadii, colors }, variant, size }) => {
    // Sizing
    let padding = `0 ${space.medium}`;
    let height = space.xLarge;
    let gap = space.xxxSmall;
    let typography: ThemeTypographyKeys = 'bodyBold';

    if (size === 'xxSmall') {
      padding = `0 ${space.xxSmall}`;
      typography = 'bodySmallBold';
      height = space.large;
    }

    if (size === 'small') {
      typography = 'bodySmallBold';
      height = space.large;
    }

    if (size === 'fluidSmallToMedium') {
      padding = `0 ${space.fluidXxSmallToMedium}`;
      height = space.fluidLargeToXLarge;
      gap = space.fluidXxxxxSmallToXxxSmall;
    }

    return css`
      display: inline-flex;
      justify-content: center;
      cursor: pointer;
      align-items: center;
      gap: ${gap};
      padding: ${padding};
      border: solid 2px ${colors.darkBlue};
      text-decoration: none;
      border-radius: ${borderRadii.small};
      height: ${height};
      ${useBackgroundColor(variant || 'neutral')}
      ${useResponsiveType(typography)};
      transition: all 0.2s ease-in-out;



      // Hover

      &:hover {
        box-shadow: 0 0 0 1px ${colors.darkBlue};
        opacity: 0.9;
      }

      // Focused

      &:focus {
        outline: none;
        box-shadow: 0 0 0 4px ${colors.warningYellow};
      }

      // Disabled

      &:disabled {
        cursor: default;
        opacity: 0.2;
        pointer-events: none;
      }
      ${variant === 'zemplerBlue' &&
      css`
        &:disabled {
          ${useBackgroundColor('neutral')};
        }
      `}

      // If variant is neutralOutline
      // Add border and remove background

      ${variant === 'neutralOutline' &&
      css`
        border-color: currentColor;
      `}

    `;
  }};
`;

export const Button = forwardRef<ButtonType, ButtonProps>(
  (
    {
      children,
      a11yTitle,
      onClick,
      disabled = false,
      variant = 'neatural',
      url,
      as,
      label,
      iconLeft,
      iconRight,
      arrowRight,
      arrowLeft,
      dataTest,
      target,
      state,
      size = 'medium',
      disableClickTracking,
      customDataLayerEvent,
      dataLayerEvent,
      dataLayerClickContext,
      ...rest
    },
    ref
  ) => {
    const { dataLayerPush } = useDataLayerPush();
    const { sectionId } = useContext(SectionContext);

    // Handle click

    const handleClick = (...props) => {
      if (!disableClickTracking) {
        dataLayerPush(
          customDataLayerEvent
            ? {
                ...customDataLayerEvent,
              }
            : {
                event: dataLayerEvent || 'button_click',
                clickText: label || children,
                clickContext: dataLayerClickContext || sectionId,
                ...getUrlParts(url as string),
              }
        );
      }
      if (onClick) {
        onClick(...props);
      }
    };

    // Icons

    const IconLeft = iconLeft;
    const IconRight = iconRight;

    // Component as

    let component: ButtonTypes;

    if (as) {
      component = as;
    } else if (!url) {
      component = 'button';
    } else {
      component = LinkTo;
    }

    return (
      <StyledButton
        ref={ref}
        to={url}
        as={component}
        onClick={handleClick}
        aria-label={a11yTitle}
        variant={variant}
        disabled={disabled}
        aria-disabled={disabled}
        target={target}
        state={state}
        size={size}
        {...(dataTest && { 'data-test': dataTest })}
        {...rest}
      >
        {arrowLeft && <ArrowLeft />}
        {iconLeft && !arrowLeft ? IconLeft : ''}
        {children || label}
        {iconRight && !arrowRight ? IconRight : ''}
        {arrowRight && <ArrowRight />}
      </StyledButton>
    );
  }
);

Button.displayName = 'Button';

export default Button;
