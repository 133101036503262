import React, { FC } from "react";
import { Icon, IconProps } from "../Icon";

export const Cross: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M2 0a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Zm3 4c.256 0 .512.097.707.293L9 7.586l3.293-3.293a.997.997 0 0 1 1.414 0 .999.999 0 0 1 0 1.414L10.414 9l3.293 3.293a1 1 0 1 1-1.414 1.414L9 10.414l-3.293 3.293a.997.997 0 0 1-1.414 0 .999.999 0 0 1 0-1.414L7.586 9 4.293 5.707A.999.999 0 0 1 5 4Z"
    />
  </Icon>
);
