import {
  Pound,
  Download,
  Help,
  Percentage,
  Pen,
  Idea,
  ArrowRight,
  Info,
  Padlock,
  Phone,
  Tick,
  Email,
  Address,
  Alert,
  Smile,
} from '.';

// Interface

export const IconMap = {
  Info,
  Padlock,
  Tick,
  Phone,
  Email,
  Address,
  Alert,
  Smile,
  ArrowRight,
  Idea,
  Pen,
  Percentage,
  Help,
  Download,
  Pound,
};
